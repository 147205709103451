import React, { useState, useEffect, useCallback, useContext } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@material-ui/core";
import ImagePreview from "../../components/ImagePreview";
import Dropzone from "../../components/Dropzone";
import useSmsBroadcast from "../../hooks/useSmsBroadcast";
import AddLink from "../../components/AddLinkButton.js";
import { BeakerIcon, PaperAirplaneIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import AutocompleteCombo from "components/AutocompleteCombo";
import { navigate } from "hookrouter";
import UserContext from "contexts/UserContext";
import { useMemo } from "react";
import NoPhoneEventTooltip from "components/NoPhoneEventTooltip";
import { getTimeZone, timeZoneAbbreviation } from "utils/formatter";
import DateTimeSelector from "components/DateTimeSelector";

const sendOptions = [
  { id: 'scheduled', name: 'Send Later', description: 'Select when your message should be sent (at least three minutes from now):' },
  { id: 'send', name: 'Send Now', description: 'Your messages will start sending right away.' },
];

const SmsBroadcastForm = (props) => {
  const id = props.id;
  const smsBroadcast = useSmsBroadcast();
  const {
    fetchBroadcast,
    setName,
    isFormValid,
    onSubmit,
    onUpdate,
    onCreate,
    onCreateAndQueue,
    onDelete,
    fetchContactLists,
    contactLists,
    setBroadcastList,
    broadcastList,
    message,
    setMessage,
    setDate,
    handleFile,
    setAttachments,
    loading,
    broadcast,
    setBroadcast,
    setLoadedAttachment,
    name,
    onSendTest,
    handleDeleteFile,
    loadedAttachment,
    attachments,
    date,
    link,
    setLink,
    addLink,
    handleModalOpen,
    handleModalClose,
    open,
    utmCampaign,
    setUtmCampaign,
    linkError,
    scheduleOption,
    setScheduleOption
  } = smsBroadcast;
  const [isNew, setIsNew] = useState(false);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [sendTestDialogOpen, setSendTestDialogOpen] = useState(false);

  const [dateTimeError, setDateTimeError] = useState(false);
  
  const { excludeSmsFeatureFlag, eventId, currentEventPhone, hasEventPhone, phone } = useContext(UserContext);

  useEffect(() => {
    const getBroadcast = async () => {
      if (props.id) {
        await fetchBroadcast(parseInt(props.id));
        fetchContactLists("", props.id);
        setIsNew(false);

        // for email broadcasts we don't want to allow editing for now
        if (broadcast.channel && broadcast.channel !== "sms") {
          navigate(`/events/${eventId}/broadcasts/${props.id}`);
        }
      } else {
        // if the exclude sms feature flag is enabled we don't want to allow users to create a new broadcast
        if (excludeSmsFeatureFlag) {
          navigate(`/events/${eventId}/broadcasts`);
        }

        setIsNew(true);
        fetchContactLists("", props.id);
        setBroadcast({});
      }
    };

    getBroadcast();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id, broadcast.channel, excludeSmsFeatureFlag, eventId]);

  const onChange = useCallback((list) => {
    setBroadcastList(list);
  }, [setBroadcastList]);

  const handleClose = useCallback(() => setDeleteDialogOpen(false), []);
  
  const handleDelete = useCallback(() => {
    setDeleteDialogOpen(false);
    onDelete();
  }, [onDelete]);

  const deleteDialog = useCallback(
    () => (
      <Dialog
        open={deleteDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Broadcast?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this broadcast?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            autoFocus
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={handleDelete}
          >
            Delete
          </button>
        </DialogActions>
      </Dialog>
    ),
    [deleteDialogOpen, handleClose, handleDelete]
  );

  const sendTestDialog = useCallback(
    () => (
      <Dialog
        open={sendTestDialogOpen}
        onClose={() => setSendTestDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Send a test message to your mobile phone.</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>From: {currentEventPhone}</p>
            <p>To: {phone}</p>
            <p className="mt-3">
              <i>You can change your phone number in <a className="text-blue-500 underline" href="/settings" rel="noreferrer">Settings</a>.</i>
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={() => setSendTestDialogOpen(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            autoFocus
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => {onSendTest(); setSendTestDialogOpen(false);}}
          >
            Send
          </button>
        </DialogActions>
      </Dialog>
    ),
    [sendTestDialogOpen]
  );

  const canSendTestMessage = useMemo(() => message && !loading && hasEventPhone && phone, [message, loading, hasEventPhone, phone]);
  const candConfirmAndSendMessage = useMemo(() => isFormValid() && !loading && hasEventPhone, [isFormValid, loading, hasEventPhone]);

  return (
    <div>
      {deleteDialog()}
      {sendTestDialog()}
      {!loading && (
        <div className="items-center">
          <div className="">
            <div className="w-full">
              <h3><b>Broadcast Name</b></h3>
              <label
                className="text-gray-500 text-base leading-6 mb-2 block"
                id="name-label"
              >
                (For internal reference only. Not visible to your recipients.)
              </label>
              <div className="w-full">
                <TextField
                  name="name"
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <br/>
              </div>
            </div>
            <br />
            <div className="w-full">
              <h3><b>Message</b></h3>
              <label
                className="text-gray-500 text-base leading-6 mb-2 block"
                id="message"
              >
                Compose your text message:
              </label>
              <textarea
                rows={10}
                cols={20}
                name="message"
                value={message}
                placeholder="Type something..."
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                onChange={(e) => setMessage(e.target.value)}
              />
              <div className="flex justify-end">
                <small>
                  {message.length}/160 characters
                </small>
              </div>
              <div>
                <AddLink
                  type="button"
                  link={link}
                  setLinkState={setLink}
                  addLinkClickHandler={addLink}
                  handleModalOpen={handleModalOpen}
                  handleModalClose={handleModalClose}
                  modalStatus={open}
                  utmCampaign={utmCampaign}
                  setUtmCampaign={setUtmCampaign}
                  isError={linkError}
                />
                <div className="ml-[10px] mt-2 sm:mt-0 inline-block mb-4 sm:mb-0">
                  { (!hasEventPhone || !phone)&&
                    <NoPhoneEventTooltip elementId="#send-test-message-button" eventPhone={hasEventPhone} phone={phone}/>
                  }
                  <button
                    id="send-test-message-button"
                    type="button"
                    disabled={!canSendTestMessage}
                    className="btn push-right mt-2 sm:mt-0 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-25"
                    onClick={() => setSendTestDialogOpen(true)}
                  >
                    <BeakerIcon className="h-5 w-5 mr-2"/>
                    Send Test...
                  </button>
                  <br />
                </div>
              </div>
            </div>
            <br />

            <div className="w-full">
              <h3><b>Image (Optional)</b></h3>
              <label
                className="text-gray-500 text-base leading-6 mb-2 block"
                id="image-label"
              >
                Upload an image to include it in your message:
              </label>

            {!isNew && loadedAttachment ? (
                  <ImagePreview
                    fileUrl={loadedAttachment.fileUrl}
                    fileName={loadedAttachment.filename}
                    handleDelete={() =>
                      handleDeleteFile(loadedAttachment.id, () => {
                        setAttachments([]);
                        setLoadedAttachment(null);
                      })
                    }
                  />
                ) : (
                  <Dropzone
                    handleFile={handleFile}
                    attachments={attachments}
                    handleDelete={setAttachments}
                  />
                )}
                <br />
            </div>
            <br />

            <div className="w-full">
              <h3><b>Recipients</b></h3>
              <label
                className="text-gray-500 text-base leading-6 mb-2 block"
                id="recipient"
              >
                Choose which list should receive this message:
              </label>
              <AutocompleteCombo
                options={contactLists}
                selectedValue={broadcastList}
                onChange={onChange}
                optionKeyAttribute="id"
                optionLabelAttribute="name"
                secondaryOptionLabelAttribute="size"
              />
              <br />
            </div>
            <br />
            
            <div className="mb-8">
              <h3 className="font-bold">Schedule</h3>
              <div className="space-y-4 mt-2">
                {sendOptions.map((option) => (
                  <>
                    <div key={option.id} className="relative flex items-start">
                      <div className="flex h-6 items-center">
                        <input
                          id={option.id}
                          name="option"
                          type="radio"
                          aria-describedby={`${option.id}-description`}
                          className="size-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer"
                          checked={scheduleOption === option.id}
                          onChange={(e) => setScheduleOption(option.id)}
                        />
                      </div>
                      <div className="ml-3 text-sm/6">
                        <label htmlFor={option.id} className="font-medium text-gray-900">
                          {option.name}
                        </label>
                        <p id={`${option.id}-description`} className="text-gray-500">
                          {option.description}
                        </p>
                      </div>
                    </div>
                    {option.id === 'scheduled' && scheduleOption === 'scheduled' ? (
                      <div className="ml-6 sm:ml-16">
                        <div className='flex justify-start my-2'>
                          <DateTimeSelector dateTime={date} setDateTime={setDate} dateTimeError={dateTimeError} setDateTimeError={setDateTimeError} />
                        </div>
                        <p>{getTimeZone()} ({timeZoneAbbreviation()})</p>
                      </div>
                    ) : null}
                  </>
                ))}
              </div>
            </div>

            <div className="flex items-start justify-start space-y-0">
              <button
                className="!mr-2 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-25"
                onClick={id ? onUpdate : onCreate}
              >
                <PencilSquareIcon className="h-5 w-5 mr-2 sm:block hidden"/>
                Save Draft
              </button>
              { (!hasEventPhone || !phone)&&
                <NoPhoneEventTooltip elementId="#send-test-message-button" eventPhone={hasEventPhone} phone={phone}/>
              }
              <button
                id="confirm-and-send-message-button"
                className="btn !mr-2 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-25"
                onClick={id ? onSubmit : onCreateAndQueue}
                disabled={!candConfirmAndSendMessage}
              >
                <PaperAirplaneIcon className="h-5 w-5 mr-2 sm:block hidden"/>
                {scheduleOption === 'scheduled' ? 'Schedule Send' : 'Send Now'}
              </button>
              { id &&
                <button
                  type="button"
                  className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:ring-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                  onClick={() => setDeleteDialogOpen(true)}
                >
                  <TrashIcon className="-ml-1 mr-3 h-5 w-5 sm:block hidden" aria-hidden="true" />
                  Delete<span className="hidden sm:block">...</span>
                </button>
              }
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SmsBroadcastForm;
