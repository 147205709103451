import useRollbar from "./useRollbar";
import { useCallback, useContext, useState } from "react";
import { useSnackbar } from "notistack";
import useRouter from "./useRouter";
import moment from "moment";
import useApi from "./useApi";
import {
  CREATE_SMS_BROADCAST,
  DELETE_SMS_BROADCAST,
  REMOVE_ATTACHMENT,
  TEST_SMS_MESSAGE,
  UPDATE_BROADCAST_STATUS,
  UPDATE_SMS_BROADCAST
} from "../utils/mutations";
import { GET_BROADCAST, LIST_SEARCH } from "../utils/queries";
import UserContext from "contexts/UserContext";

const useSmsBroadcast = () => {
  const { captureError } = useRollbar();
  const defaultBroadCastName = "Scheduled Message";
  const [name, setName] = useState(defaultBroadCastName);
  const [uniqueLink, setUniqueLink] = useState(false);
  const [shortenedLinks, setShortenedLinks] = useState({});
  const [date, setDate] = useState(null);
  const [status, setStatus] = useState("draft");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadedAttachment, setLoadedAttachment] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { navigate } = useRouter();
  const [attachments, setAttachments] = useState(null);

  const { apolloClient, mutate, query } = useApi();
  const [broadcast, setBroadcast] = useState({});
  const [contactLists, setContactLists] = useState([]);
  const [broadcastList, setBroadcastList] = useState(null);
  const [open, setOpen] = useState(false);
  const [linkError, setLinkError] = useState(false);
  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => setOpen(false);
  const { eventId } = useContext(UserContext);

  const [scheduleOption, setScheduleOption] = useState('scheduled');

  const {
    REACT_APP_FIREBASE_API_KEY,
    REACT_APP_FIREBASE_DYNAMIC_LINKS_BASE_URL,
    REACT_APP_FIREBASE_BASE_URL,
  } = process.env;
  const [utmCampaign, setUtmCampaign] = useState("");
  const handleFile = (file) => {
    setAttachments(file);
  };

  const handleDeleteFile = useCallback(
    async (attachmentId, callback) => {
      mutate(REMOVE_ATTACHMENT(attachmentId)).then(() => {
        callback();
      });
    },
    [mutate]
  );

  const [link, setLink] = useState("");
  const isRegexValid = useCallback(() => {
    const regexp =
      /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

    if (link !== "" && regexp.test(link.toLowerCase())) {
      return true;
    } else {
      return "Invalid Link entered";
    }
  }, [link]);

  const filteredShortenedLinks = useCallback(() => {
    if (!shortenedLinks) return null;

    return Object.keys(shortenedLinks)
        .filter((link) => message.includes(link.toLowerCase()))
        .reduce((cur, link) => { return Object.assign(cur, { [link]: shortenedLinks[link] })}, {});
  }, [shortenedLinks, message]);

  const addLink = async (handleModalOpen) => {
    const isRegExpValid = isRegexValid();
    if (isRegExpValid !== true) {
      setLinkError(true);
      return;
    }

    if (linkError) setLinkError(false)

    try {
      let sendLink = link;
      let firebase_url = "";
      if (!sendLink.includes("http")) {
        firebase_url = `http://${sendLink}`;
      }
      fetch(
        `${REACT_APP_FIREBASE_BASE_URL}shortLinks?key=${REACT_APP_FIREBASE_API_KEY}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            dynamicLinkInfo: {
              domainUriPrefix: REACT_APP_FIREBASE_DYNAMIC_LINKS_BASE_URL,
              link: firebase_url ? firebase_url : sendLink,
            },
            suffix: {
              option: "SHORT",
            },
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          // Add new shortened link to the state
          let shortenedLinksCopy = Object.assign({}, filteredShortenedLinks());
          shortenedLinksCopy[data.shortLink] = sendLink;
          setShortenedLinks(shortenedLinksCopy);
          if (message === "")
            setMessage(data.shortLink);
          else
            setMessage(message + "\n" + data.shortLink);
          // enqueueSnackbar("Short link added in message successfully", {
          //   autoHideDuration: 3000,
          //   variant: "success",
          // });
        });
    } catch (e) {
      enqueueSnackbar(`Failed to generate short link ${e}`, {
        autoHideDuration: 3000,
        variant: "error",
      });
      captureError(e);
      setLoading(false);
    }
    setOpen(false);
    setLink("");
    setUtmCampaign("");
  };

  const onCreate = async () => {
    const sendAt = date ? new Date(date).toUTCString() : null;
    if (!validateBroadcast()) return;

    try {
      setLoading(true);
      const {
        data: { createSmsBroadcast: { id } }
      } = await apolloClient.mutate({
        mutation: CREATE_SMS_BROADCAST,
        variables: {
          eventId: parseInt(eventId),
          name: name,
          uniqueLink: uniqueLink,
          shortenedLinks: filteredShortenedLinks(),
          sendAt: sendAt,
          message: message,
          files: attachments,
          status: "draft",
          isImmediate: scheduleOption === 'send',
          ...(broadcastList && { listId: parseInt(broadcastList.id) }),
        },
      });
      enqueueSnackbar("Broadcast has been saved", {
        autoHideDuration: 3000,
        variant: "success",
      });
      setLoading(false);
      navigate(`/events/${eventId}/broadcasts/${id}`);
    } catch (e) {
      enqueueSnackbar("Failed to create the broadcast:" + e.message, {
        autoHideDuration: 3000,
        variant: "error",
      });
      console.error(e);
      captureError(e);
      setLoading(false);
    }
  };

  const onUpdate = async () => {
    const sendAt = date ? new Date(date).toUTCString() : null;
    if (!validateBroadcast()) return;

    try {
      setLoading(true);
      await apolloClient.mutate({
        mutation: UPDATE_SMS_BROADCAST,
        variables: {
          broadcastId: parseInt(broadcast.id),
          name: name,
          uniqueLink: uniqueLink,
          shortenedLinks: filteredShortenedLinks(),
          sendAt: sendAt,
          message: message,
          listId: parseInt(broadcastList?.id),
          files: attachments,
          status: "draft",
          isImmediate: scheduleOption === 'send',
        },
      });
      enqueueSnackbar("Broadcast has been saved", {
        autoHideDuration: 3000,
        variant: "success",
      });
      setLoading(false);
      navigate(`/events/${eventId}/broadcasts/${broadcast.id}`);
    } catch (e) {
      enqueueSnackbar("Failed to create the broadcast:" + e.message, {
        autoHideDuration: 3000,
        variant: "error",
      });
      console.error(e);
      captureError(e);
      setLoading(false);
    }
  };

  const onUpdateStatus = async (status = "draft") => {
    try {
      setLoading(true);
      await apolloClient.mutate({
        mutation: UPDATE_BROADCAST_STATUS,
        variables: {
          broadcastId: parseInt(broadcast.id),
          status: status,
          listId: parseInt(broadcastList?.id),
          uniqueLink: broadcast.uniqueLink
        },
      });
      enqueueSnackbar(`Broadcast has changed to ${status}`, {
        autoHideDuration: 3000,
        variant: "success",
      });
      setLoading(false);
      if (status === "draft") {
        navigate(`/events/${eventId}/broadcasts/edit/${broadcast.id}`);
      } else {
        navigate(`/events/${eventId}/broadcasts`);
      }
    } catch (e) {
      enqueueSnackbar("Failed to change broadcast status:" + e.message, {
        autoHideDuration: 3000,
        variant: "error",
      });
      console.error(e);
      captureError(e);
      setLoading(false);
    }
  };

  const onSubmit = async () => {
    if (!isFormValid()) {
      enqueueSnackbar("Required field missing", {
        autoHideDuration: 3000,
        variant: "error",
      });
      return;
    }

    if (!validateBroadcast()) return;

    try {
      setLoading(true);
      await apolloClient.mutate({
        mutation: UPDATE_SMS_BROADCAST,
        variables: {
          broadcastId: parseInt(broadcast.id),
          name: name,
          uniqueLink: uniqueLink,
          shortenedLinks: filteredShortenedLinks(),
          sendAt: new Date(date).toUTCString(),
          listId: parseInt(broadcastList?.id),
          message: message,
          files: attachments,
          status: "queued",
          isImmediate: scheduleOption === 'send',
        },
      });
      enqueueSnackbar(`Broadcast has been ${scheduleOption}`, {
        autoHideDuration: 3000,
        variant: "success",
      });
      setLoading(false);
      navigate(`/events/${eventId}/broadcasts/${broadcast.id}`);
    } catch (e) {
      enqueueSnackbar("Failed to create the broadcast:" + e.message, {
        autoHideDuration: 3000,
        variant: "error",
      });
      captureError(e);
      setLoading(false);
    }
  };

  const onDelete = async () => {
    try {
      setLoading(true);
      await apolloClient.mutate({
        mutation: DELETE_SMS_BROADCAST,
        variables: {
          eventId: parseInt(eventId),
          broadcastId: parseInt(broadcast.id)
        }
      });
      enqueueSnackbar("Broadcast has been deleted", {
        autoHideDuration: 3000,
        variant: "success",
      });
      setLoading(false);
      navigate(`/events/${eventId}/broadcasts`);
    } catch (e) {
      enqueueSnackbar("Failed to delete the broadcast:" + e.message, {
        autoHideDuration: 3000,
        variant: "error",
      });
      console.error(e);
      captureError(e);
      setLoading(false);
    }
  };

  const onCreateAndQueue = async () => {
    if (!isFormValid()) {
      enqueueSnackbar("Required field missing", {
        autoHideDuration: 3000,
        variant: "error",
      });
      return;
    }

    if (!validateBroadcast()) return;

    try {
      setLoading(true);
      const {
        data: { createSmsBroadcast: { id } }
      } = await apolloClient.mutate({
        mutation: CREATE_SMS_BROADCAST,
        variables: {
          eventId: parseInt(eventId),
          name: name,
          uniqueLink: uniqueLink,
          shortenedLinks: filteredShortenedLinks(),
          sendAt: new Date(date).toUTCString(),
          message: message,
          files: attachments,
          status: "queued",
          listId: parseInt(broadcastList?.id),
          isImmediate: scheduleOption === 'send',
        },
      });
      enqueueSnackbar(`Broadcast has been ${scheduleOption}`, {
        autoHideDuration: 3000,
        variant: "success",
      });
      setLoading(false);
      navigate(`/events/${eventId}/broadcasts/${id}`);
    } catch (e) {
      enqueueSnackbar("Failed to create the broadcast:" + e.message, {
        autoHideDuration: 3000,
        variant: "error",
      });
      console.error(e);
      captureError(e);
      setLoading(false);
    }
  };

  const onSendTest = useCallback(async () => {
    if (message) {
      try {
        setLoading(true);
        await apolloClient.mutate({
          mutation: TEST_SMS_MESSAGE,
          variables: { name: name, message: message, eventId: parseInt(eventId), broadcastId: parseInt(broadcast.id), files: attachments },
        });
        enqueueSnackbar("A test message has been sent to your mobile", {
          autoHideDuration: 3000,
          variant: "success",
        });
        setLoading(false);
      } catch (e) {
        enqueueSnackbar("Failed to send a sms test message:" + e.message, {
          autoHideDuration: 3000,
          variant: "error",
        });
        console.error(e);
        captureError(e);
        setLoading(false);
      }
    }
  }, [eventId, message, attachments, apolloClient, name, enqueueSnackbar, captureError, broadcast]);

  const isFormValid = useCallback(() => {
    return name && date && message && broadcastList;
  }, [broadcastList, date, message, name]);

  const fetchBroadcast = useCallback(async (id) => {
    setLoading(true);

    try {
      if (!id) {
        return;
      }

      if (!eventId) {
        return;
      }

      const {
        data: { broadcast },
      } = await query(
        GET_BROADCAST({ eventId: eventId, id: id })
      );

      setName(broadcast.name);
      setUniqueLink(broadcast.uniqueLink);
      setShortenedLinks(broadcast.shortenedLinks);
      setBroadcastList(broadcast.list ?? null);
      setScheduleOption(broadcast?.isImmediate ? 'send' : 'scheduled');
      if (broadcast.template && broadcast.template.attachments[0]) {
        setLoadedAttachment(broadcast.template.attachments[0]);
      }
      if (broadcast.sendDate) {
        // setDate(moment(broadcast.sendDate).format("YYYY-MM-DDTHH:mm"));
        setDate(new Date(broadcast.sendDate));
      }
      setStatus(broadcast.status);
      if (broadcast.template && broadcast.template.body) {
        setMessage(broadcast.template.body);
      }
      setBroadcast(broadcast);
    } catch (e) {
      console.log("Failed to fetch broadcasts", e);
      console.error(e);
      captureError(e);
    }

    setLoading(false);
  }, [eventId, captureError, query]);

  const fetchContactLists = useCallback(
    async (searchTerm = "", broadcastId = null) => {
      if (typeof searchTerm !== "string") searchTerm = "";
      try {
        const res = await query(LIST_SEARCH(searchTerm, eventId, broadcastId));
        setContactLists(res.data.listSearch.lists);
      } catch (e) {
        captureError(e);
        console.log("Failed to load lists", e);
      }
    },
    [eventId, query, captureError]
  );

  const validateBroadcast = useCallback(() => {
    const sendAt = date ? new Date(date).toUTCString() : null;
    const now = moment.utc();
    let valid = true;
    if (scheduleOption === 'scheduled' && sendAt && moment.utc(sendAt) < now) {
      console.log('coming', scheduleOption)
      valid = false;
      enqueueSnackbar("Scheduled date cannot be in the past", {
        autoHideDuration: 3000,
        variant: "error",
      });
    }

    return valid;
  }, [date, scheduleOption, enqueueSnackbar]);

  return {
    fetchBroadcast,
    onSendTest,
    onUpdate,
    onUpdateStatus,
    onSubmit,
    onCreate,
    onCreateAndQueue,
    onDelete,
    isFormValid,
    setLoadedAttachment,
    handleDeleteFile,
    handleFile,
    setName,
    name,
    setUniqueLink,
    uniqueLink,
    setShortenedLinks,
    shortenedLinks,
    setDate,
    date,
    setMessage,
    message,
    loading,
    setLoading,
    setAttachments,
    setStatus,
    status,
    loadedAttachment,
    broadcast,
    setBroadcast,
    fetchContactLists,
    contactLists,
    setBroadcastList,
    broadcastList,
    attachments,
    link,
    isRegexValid,
    setLink,
    addLink,
    handleModalOpen,
    handleModalClose,
    open,
    utmCampaign,
    setUtmCampaign,
    linkError,
    scheduleOption,
    setScheduleOption
  };
};

export default useSmsBroadcast;
