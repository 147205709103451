import { useCallback, useContext, useState } from "react";
import useRollbar from "./useRollbar";
import useApi from "hooks/useApi";
import { GET_EVENT_SESSIONS } from "utils/queries";
import { CREATE_EVENT_SESSION, DELETE_EVENT_SESSION, UPDATE_EVENT_SESSION } from "utils/mutations";
import { enqueueSnackbar } from "notistack";
import { defaultNearest15minutesDate } from "utils/misc";
import UserContext from "contexts/UserContext";

const useSchedules = () => {
  const { captureError } = useRollbar();
  const { apolloClient, query } = useApi();
  const { eventId } = useContext(UserContext);

  const [loading, setLoading] = useState(true);
  const [sessions, setSessions] = useState([]);
  const [activeSession, setActiveSession] = useState(0)

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [locationName, setLocationName] = useState('');
  const [locationUrl, setLocationUrl] = useState('');
  const [startDateTime, setStartDateTime] = useState(null);
  const [endDateTime, setEndDateTime] = useState(defaultNearest15minutesDate().add(30, 'minutes').toDate());
  const [filterBy, setFilterBy] = useState('all');
  const [listId, setListId] = useState('');
  const [customerId, setCustomerId] = useState('');

  const fetchSessions = useCallback(async () => {
    setLoading(true);

    if (!eventId) {
      return;
    }

    try {
      const { data } = await query(GET_EVENT_SESSIONS(eventId));
      if (data?.sessions) setSessions(data?.sessions);
    } catch (e) {
      captureError(e);
      console.log("Failed to load sessions", e);
    } finally {
      setLoading(false);
    }
  }, [captureError, query]);

  const onCreate = async () => {
    setLoading(true);

    const variables = {
      eventId: parseInt(eventId),
      name,
      description,
      locationName,
      locationUrl,
      startDatetimeUtc: new Date(startDateTime).toUTCString(),
      endDatetimeUtc: new Date(endDateTime).toUTCString(),
    };

    if (filterBy === 'all' || filterBy === 'team') {
      variables.filterBy = filterBy;
    }

    if (filterBy === 'list') {
      variables.listId = parseInt(listId);
    }

    if (filterBy === 'individual') {
      variables.listId = parseInt(customerId);
    }
    
    try {
      await apolloClient.mutate({
        mutation: CREATE_EVENT_SESSION,
        variables
      });

      enqueueSnackbar("Event session has been created", {
        autoHideDuration: 3000,
        variant: "success",
      });

      setName('');
      setDescription('');
      setLocationName('');
      setLocationUrl('');
      setStartDateTime(null);
      setEndDateTime(defaultNearest15minutesDate().add(30, 'minutes').toDate());
      setFilterBy('all');
      setListId('')
      setCustomerId('')

      await fetchSessions();
    } catch (e) {
      enqueueSnackbar(`${e.message.replace("GraphQL error:", "")}.`, {
        autoHideDuration: 3000,
        variant: "error",
      });
      console.error(e);
      captureError(e);
      return false;
    } finally {
      setLoading(false);
    }

    return true;
  };

  const onUpdate = async () => {
    setLoading(true);

    const variables = {
      id: parseInt(activeSession),
      name,
      description,
      locationName,
      locationUrl,
      startDatetimeUtc: new Date(startDateTime).toUTCString(),
      endDatetimeUtc: new Date(endDateTime).toUTCString(),
    };

    if (filterBy === 'all' || filterBy === 'team') {
      variables.filterBy = filterBy;
    }

    if (filterBy === 'list') {
      variables.listId = parseInt(listId);
    }

    if (filterBy === 'individual') {
      variables.listId = parseInt(customerId);
    }
    
    try {
      await apolloClient.mutate({
        mutation: UPDATE_EVENT_SESSION,
        variables
      });

      enqueueSnackbar("Event session has been updated", {
        autoHideDuration: 3000,
        variant: "success",
      });

      setName('');
      setDescription('');
      setLocationName('');
      setLocationUrl('');
      setStartDateTime(null);
      setEndDateTime(defaultNearest15minutesDate().add(30, 'minutes').toDate());
      setActiveSession(0);

      await fetchSessions();
    } catch (e) {
      enqueueSnackbar(`${e.message.replace("GraphQL error:", "")}.`, {
        autoHideDuration: 3000,
        variant: "error",
      });
      console.error(e);
      captureError(e);
      return false;
    } finally {
      setLoading(false);
    }

    return true;
  };

  const onDelete = useCallback(async (id) => {
    if (!id) {
      enqueueSnackbar("Event session id is required!" , {
        autoHideDuration: 3000,
        variant: "error"
      });
      return;
    }

    setLoading(true);

    try {
      await apolloClient.mutate({
        mutation: DELETE_EVENT_SESSION,
        variables: {
          id: parseInt(id)
        }
      });
      enqueueSnackbar("Event session has been deleted", {
        autoHideDuration: 3000,
        variant: "success",
      });

      await fetchSessions();
    } catch (e) {
      enqueueSnackbar(`${e.message.replace("GraphQL error:", "")}.`, {
        autoHideDuration: 3000,
        variant: "error",
      });
      console.error(e);
      captureError(e);
    } finally {
      setLoading(false);
    }
  }, [apolloClient, enqueueSnackbar, captureError]);

  return {
    loading,
    setLoading,
    sessions,
    setSessions,
    name,
    setName,
    description,
    setDescription,
    locationName,
    setLocationName,
    locationUrl,
    setLocationUrl,
    startDateTime,
    setStartDateTime,
    endDateTime,
    setEndDateTime,
    fetchSessions,
    onCreate,
    onUpdate,
    onDelete,
    activeSession,
    setActiveSession,
    filterBy,
    setFilterBy,
    listId,
    setListId,
    customerId,
    setCustomerId
  };
};

export default useSchedules;
