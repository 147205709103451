import { useEffect, useMemo, useRef, useState, forwardRef } from 'react'
import { CalendarIcon, ChevronDownIcon, ClockIcon } from '@heroicons/react/24/outline'
import { defaultNearest15minutesDate } from 'utils/misc';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const DateTimeSelector = ({ dateTime, setDateTime, dateTimeError, setDateTimeError = () => {}, editEnabled = false }) => {
  const [today, setToday] = useState(moment().startOf('day').toDate())

  const [scheduleDate, setScheduleDate] = useState(dateTime ? dateTime : (defaultNearest15minutesDate().startOf('day').isAfter(today) ? moment().add(1, 'days').startOf("day").toDate() : today))
  const [scheduleTime, setScheduleTime] = useState(dateTime ? dateTime : defaultNearest15minutesDate().toDate())

  const scheduleTimeRef = useRef(scheduleTime);

  useEffect(() => {
    const callFunction = () => {
      const now = new Date();
      const twoMinutesLater = new Date(now.getTime() + 2 * 60000); // Add 2 minute

      // Check if the current time plus 2 minute is greater than or equal to the scheduleTime
      if (now < scheduleTimeRef.current && twoMinutesLater >= scheduleTimeRef.current) {
        const newTime = new Date(twoMinutesLater.getTime() + 1 * 60000);

        if (moment(newTime).startOf('day').isAfter(moment(scheduleTimeRef.current).startOf('day'))) {
          const nextDay = moment().add(1, 'days').startOf('day').toDate();

          setToday(nextDay)
          setScheduleDate(nextDay)
        }

        scheduleTimeRef.current = newTime;
        setScheduleTime(newTime);
      }
    };

    // Calculate the time until the next minute change
    const now = new Date();
    const msUntilNextMinute = (60 - now.getSeconds()) * 1000 - now.getMilliseconds();

    // Set a timeout to start the interval at the next minute change
    const timeoutId = setTimeout(() => {
      callFunction();
      const intervalId = setInterval(callFunction, 60000); // Call every minute

      // Clear the interval on component unmount
      return () => clearInterval(intervalId);
    }, msUntilNextMinute);

    // Clear the timeout on component unmount
    return () => clearTimeout(timeoutId);
  }, [scheduleTime]);

  useEffect(() => {
    if (scheduleDate && scheduleTime) setDateTime(combineDateTime(scheduleTime))
  }, [scheduleDate, scheduleTime])

  const handleDateChange = (selectedDate) => {
    if (selectedDate?.toDateString() === today.toDateString()) {
      const nearestTime = defaultNearest15minutesDate().toDate();
      const now = new Date();

      if (nearestTime > today) {
        if (now.getMinutes() >= 55) return;

        setScheduleDate(selectedDate);
        
        const newTime = new Date(now.getTime() + 3 * 60000);
        setScheduleTime(newTime);
        scheduleTimeRef.current = newTime;
        return;
      }

      setScheduleDate(selectedDate);
      setScheduleTime(nearestTime);
      scheduleTimeRef.current = nearestTime;
    } else {
      const combinedDateTime = moment(today)
        .add(moment(selectedDate).diff(today, 'days'), 'days')
        .set({
          hour: moment(scheduleTime).hour(),
          minute: moment(scheduleTime).minute(),
          second: moment(scheduleTime).second(),
        });

      setScheduleDate(selectedDate);
      setScheduleTime(combinedDateTime.toDate());
    }
  };

  const handleTimeChange = (selectedTime) => {
    if (!selectedTime) return

    const datetime = combineDateTime(selectedTime);

    const newDate = new Date();
    newDate.setMinutes(newDate.getMinutes() + 2);
    
    // if (datetime >= newDate) {
      setScheduleTime(datetime)
      scheduleTimeRef.current = datetime;

      if (dateTimeError && setDateTimeError) setDateTimeError(false)
    // } 
  };

  const minTime = useMemo(() => {
    if (scheduleDate?.toDateString() !== today.toDateString()) return undefined;
  
    const nearestTime = defaultNearest15minutesDate().toDate();
    return nearestTime.toDateString() > today.toDateString() ? scheduleTime : nearestTime;
  }, [scheduleDate, scheduleTime]);

  const maxTime = useMemo(() => {
    return scheduleDate?.toDateString() === today.toDateString() ? new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999) : undefined;
  }, [scheduleDate]);

  const combineDateTime = (time) => {
    const combinedDateTime = new Date(
      scheduleDate.getFullYear(),
      scheduleDate.getMonth(),
      scheduleDate.getDate(),
      time.getHours(),
      time.getMinutes(),
      time.getSeconds(),
      time.getMilliseconds()
    );

    return combinedDateTime;
  };

  const CustomDateInput = forwardRef(({ value, onClick }, ref) => {
    const today = moment().startOf('day');
    const tomorrow = moment().add(1, 'days').startOf('day');
    const scheduleMoment = moment(value);
  
    const displayValue = scheduleMoment.isSame(today, 'day') ? 'Today' : scheduleMoment.isSame(tomorrow, 'day') ? 'Tomorrow' : value;
  
    return (
      <div className="relative w-48 mr-4" onClick={onClick}>
        <CalendarIcon className='h-5 w-5 absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500 cursor-pointer' />
        <input
          type="text"
          className="w-full pl-9 pr-8 pt-2 border rounded-md focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500 border-gray-300"
          value={displayValue}
          onClick={onClick}
          readOnly
          ref={ref}
        />
        <ChevronDownIcon className='h-4 w-4 absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 cursor-pointer' />
      </div>
    );
  });

  return (
    <>
      <DatePicker
        selected={editEnabled ? moment(dateTime).startOf('day').toDate() : scheduleDate}
        minDate={editEnabled ? undefined : today}
        onChange={handleDateChange}
        dateFormat='MM/dd/yyyy'
        dayClassName={(date) => date.toDateString() == scheduleDate.toDateString() ? 'bg-indigo-600 hover:bg-indigo-700 text-white' : ''}
        customInput={<CustomDateInput />}
      />
      <DatePicker
        showIcon
        showTimeSelect
        selected={scheduleTime}
        timeIntervals={15}
        showTimeSelectOnly
        toggleCalendarOnIconClick
        dateFormat='h:mm aa'
        minTime={minTime}
        maxTime={maxTime}
        onChange={handleTimeChange}
        className={`rounded-md w-32 !pl-9 !py-2 focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500 border-gray-300 ${dateTimeError ? 'border-red-600' : ''}`}
        icon={<ClockIcon className="h-5 w-5 absolute top-1/2 transform -translate-y-1/2 text-gray-500 cursor-pointer" />}
      />
    </>
  );
};

export default DateTimeSelector;