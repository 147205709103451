import React, { Fragment, useEffect, useContext, useMemo, useRef } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/outline"
import { classNames } from "utils/misc"
import DateTimeSelector from "components/DateTimeSelector"
import SchedulesContext from "contexts/SchedulesContext"
import useSmsBroadcast from "hooks/useSmsBroadcast"
import AutocompleteCombo from "components/AutocompleteCombo"
import SimpleCombo from "components/SimpleCombo"

const SessionDialog = ({ open, closeDialog, resetFormValues }) => {
  const nameRef = useRef(null);

  const smsBroadcast = useSmsBroadcast();
  const { fetchContactLists, contactLists } = smsBroadcast;

  const {
    name,
    setName,
    description,
    setDescription,
    locationName,
    setLocationName,
    locationUrl,
    setLocationUrl,
    startDateTime,
    setStartDateTime,
    endDateTime,
    setEndDateTime,
    onCreate,
    onUpdate,
    activeSession,
    filterBy,
    setFilterBy,
    listId,
    setListId,
    customerId,
    setCustomerId
  } = useContext(SchedulesContext)

  useEffect(() => {
    nameRef.current.focus();
  }, [])

  useEffect(() => {
    if (filterBy === "list") {
      fetchContactLists();
    }
  }, [filterBy])
  
  const isSameOrAfter = (date1, date2) => date1.getTime() >= date2.getTime();

  const hasNameError = useMemo(() => name.trim() === "", [name])
  const hasDateError = useMemo(() => startDateTime && endDateTime && isSameOrAfter(startDateTime, endDateTime), [startDateTime, endDateTime]);

  const onSave = () => {
    if (hasNameError || hasDateError) return;

    activeSession ? onUpdate() : onCreate()
    resetFormValues();
  };

  return (
    <Transition.Root appear="true" show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[1500]" onClose={closeDialog}>
        <Transition.Child
          appear="true"
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                appear="true"
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="flex min-h-0 flex-1 flex-col overflow-y-auto pt-6 px-3 sm:px-6">
                      <div className="flex items-start justify-between">
                        <div>
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            {activeSession ? 'Edit' : 'Add'} Event Session
                          </Dialog.Title>
                          {!activeSession && (
                            <Dialog.Description className="mt-2">
                              Add additional sessions to your event schedule.
                            </Dialog.Description>
                          )}
                        </div>

                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                            onClick={closeDialog}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <form className="space-y-4 pb-5 pt-6">
                        <div>
                          <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                            Name
                          </label>
                          <div className="mt-2">
                            <input
                              id="name"
                              name="name"
                              type="text"
                              className={classNames(
                                hasNameError ? "border-red-600" : "border-0 ring-gray-300 ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600",
                                "block w-full rounded-md py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6"
                              )}
                              value={name || ""}
                              onChange={(e) => setName(e.target.value)}
                              ref={nameRef}
                            />
                          </div>
                        </div>
                        <div>
                          <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                            Description
                          </label>
                          <div className="mt-2">
                            <textarea
                              id="description"
                              name="description"
                              rows={4}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={description || ""}
                              onChange={e => setDescription(e.target.value)}
                            />
                          </div>
                        </div>
                        <div>
                          <label htmlFor="location-name" className="block text-sm font-medium leading-6 text-gray-900">
                            Location name
                          </label>
                          <div className="mt-2">
                            <input
                              id="location-name"
                              name="location-name"
                              type="text"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={locationName || ""}
                              onChange={e => setLocationName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div>
                          <label htmlFor="location-link" className="block text-sm font-medium leading-6 text-gray-900">
                            Location link
                          </label>
                          <div className="mt-2">
                            <input
                              id="location-link"
                              name="location-link"
                              type="text"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              placeholder="https://www.google.com/maps"
                              value={locationUrl || ""}
                              onChange={e => setLocationUrl(e.target.value)}
                            />
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm font-medium leading-6 text-gray-900 pb-1.5">Start time</label>
                          <div className="flex justify-start">
                            <DateTimeSelector
                              dateTime={startDateTime}
                              setDateTime={setStartDateTime}
                              dateTimeError={hasDateError}
                              editEnabled={!!activeSession}
                            />
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm font-medium leading-6 text-gray-900 pb-1.5">End time</label>
                          <div className="flex justify-start">
                            <DateTimeSelector
                              dateTime={endDateTime}
                              setDateTime={setEndDateTime}
                              dateTimeError={hasDateError}
                              editEnabled={!!activeSession}
                            />
                          </div>
                        </div>
                        <div>
                          <label className="text-sm/6 font-semibold text-gray-900">Assign a Session</label>
                          <div className="mt-2 space-y-3">
                            <div className="flex items-center gap-x-3">
                              <input
                                id="filter-everyone"
                                name="assign-session"
                                type="radio"
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                value="all"
                                checked={filterBy == "all"}
                                onChange={() => setFilterBy('all')}
                              />
                              <label htmlFor="filter-everyone" className="block text-sm/6 font-medium text-gray-900">
                                Everyone
                              </label>
                            </div>
                            <div className="flex items-center gap-x-3">
                              <input
                                id="filter-list"
                                name="assign-session"
                                type="radio"
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                value="list"
                                checked={filterBy == "list"}
                                onChange={() => setFilterBy('list')}
                              />
                              <label htmlFor="filter-list" className="block text-sm/6 font-medium text-gray-900">
                                A list of poeple
                              </label>
                            </div>
                            {filterBy === 'list' ? (
                              <AutocompleteCombo
                                options={contactLists}
                                selectedValue={listId ? contactLists.filter((list) => list.id == listId)[0] || null : null}
                                onChange={(list) => setListId(list.id)}
                                optionKeyAttribute="id"
                                optionLabelAttribute="name"
                                secondaryOptionLabelAttribute="size"
                                canBeCleared={false}
                              />
                            ) : null}
                            {/* <div className="flex items-center gap-x-3" onClick={() => setFilterBy('individual')}>
                              <input
                                id="filter-individual"
                                name="assign-session"
                                type="radio"
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                value="individual"
                                checked={filterBy == "individual"}
                              />
                              <label htmlFor="filter-individual" className="block text-sm/6 font-medium text-gray-900">
                                An individual
                              </label>
                            </div> */}
                            <div className="flex items-center gap-x-3">
                              <input
                                id="filter-team"
                                name="assign-session"
                                type="radio"
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                value="team"
                                checked={filterBy == "team"}
                                onChange={() => setFilterBy('team')}
                              />
                              <label htmlFor="filter-team" className="block text-sm/6 font-medium text-gray-900">
                                Your team only
                              </label>
                            </div>
                          </div>
                        </div>
                        {/* {filterBy === 'list' ? (
                          <>
                            <label className="text-sm/6 font-semibold text-gray-900">Select a list</label>
                            <AutocompleteCombo
                              options={contactLists}
                              onChange={(list) => setFilterId(list.id)}
                              optionKeyAttribute="id"
                              optionLabelAttribute="name"
                              secondaryOptionLabelAttribute="size"
                              canBeCleared={false}
                            />
                          </>
                        ) : null} */}
                        {/* : filterBy === 'individual' ? (
                          <>
                            <label className="text-sm/6 font-semibold text-gray-900">Select a people</label>
                            <SimpleCombo
                              options={[]}
                              onChange={(people) => setFilterId(people.id)}
                              canBeCleared={false}
                            />
                          </>
                        )  */}
                      </form>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-6 py-4">
                      <button
                        type="button"
                        onClick={closeDialog}
                        className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 disabled:opacity-25"
                        disabled={hasNameError || hasDateError}
                        onClick={onSave}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default SessionDialog;
