import React, { useCallback } from "react";
import { useRoutes, usePath, navigate } from "hookrouter";
import Index from "pages/Index";
import People from "pages/People";
import Customers from "pages/Customers";
import Broadcasts from "pages/Broadcasts";
import BroadcastsView from "pages/BroadcastsView";
import PersonView from "pages/PersonView";
import Billing from "pages/Billing";
import NewSmsBroadcast from "pages/NewSmsBroadcast";
import EditSmsBroadcast from "pages/EditSmsBroadcast";
import NewPerson from "pages/NewPerson";
import EditPerson from "pages/EditPerson";
import Inbox from "pages/Inbox";
import Lists from "pages/Lists";
import DeliveryIssuesReport from "pages/DeliveryIssuesReport";

import AccountSettings from "pages/AccountSettings";
import TeamSettings from "pages/TeamSettings";
import AdvancedSettings from "pages/AdvancedSettings";

import store from "store";
import PeopleImport from "pages/PeopleImport";
import PeopleImportViaFlatFile from "pages/PeopleImportViaFlatFile";
import Dashboard from "pages/Dashboard";
import AcceptInvitation from "pages/AcceptInvitation";
import { AuthenticationGuard } from "components/AuthenticationGuard";
import Schedule from "pages/Schedule";
import EditEvent from "pages/EditEvent";

const routes = {
  "/": () => <AuthenticationGuard component={Index} />,
  "/events/new": () => <AuthenticationGuard component={Dashboard} showEventModal={true} />,
  "/events/:eventId/people": ({ eventId }) => <AuthenticationGuard component={People} eventId={eventId} />,
  "/customers": () => <AuthenticationGuard component={Customers} />,
  "/invitation/accept": () => <AuthenticationGuard component={AcceptInvitation} />,
  "/event/edit/:id": ({ id }) => <AuthenticationGuard component={EditEvent} id={id} />,

  "/events/:eventId/person/new": ({ eventId }) => <AuthenticationGuard component={NewPerson} eventId={eventId} />,
  "/events/:eventId/person/:id": ({ eventId, id }) => <AuthenticationGuard component={PersonView} eventId={eventId} id={id} />,
  "/events/:eventId/person/edit/:id": ({ eventId, id }) => <AuthenticationGuard component={EditPerson} eventId={eventId} id={id} />,
  "/events/:eventId/person/:id/reminders/new": ({ eventId, id }) => <AuthenticationGuard component={PersonView} eventId={eventId} id={id} showReminderModal={true} />,

  "/events/:eventId/broadcasts": ({ eventId }) => <AuthenticationGuard component={Broadcasts} eventId={eventId} status="draft" />,
  "/events/:eventId/broadcasts/draft": ({ eventId }) => <AuthenticationGuard component={Broadcasts} eventId={eventId} status="draft" />,
  "/events/:eventId/broadcasts/queued": ({ eventId }) => <AuthenticationGuard component={Broadcasts} eventId={eventId} status="queued" />,
  "/events/:eventId/broadcasts/sending": ({ eventId }) => <AuthenticationGuard component={Broadcasts} eventId={eventId} status="sending" />,
  "/events/:eventId/broadcasts/sent": ({ eventId }) => <AuthenticationGuard component={Broadcasts} eventId={eventId} status="sent" />,

  "/events/:eventId/broadcasts/new": ({ eventId }) => <AuthenticationGuard component={NewSmsBroadcast} eventId={eventId} />,
  "/events/:eventId/broadcasts/edit/:id": ({ eventId, id }) => <AuthenticationGuard component={EditSmsBroadcast} eventId={eventId} id={id} />,
  "/events/:eventId/broadcasts/:id": ({ eventId, id }) => <AuthenticationGuard component={BroadcastsView} eventId={eventId} id={id} />,

  "/events/:eventId/inbox": ({ eventId }) => <AuthenticationGuard component={Inbox} eventId={eventId} replyStatus="inbound" />,
  "/events/:eventId/messages/inbound": ({ eventId }) => <AuthenticationGuard component={Inbox} eventId={eventId} replyStatus="inbound" />,
  "/events/:eventId/messages/sent": ({ eventId }) => <AuthenticationGuard component={Inbox} eventId={eventId} replyStatus="sent" />,
  "/events/:eventId/messages/archived": ({ eventId }) => <AuthenticationGuard component={Inbox} eventId={eventId} replyStatus="archived" />,
  "/events/:eventId/messages/assigned": ({ eventId }) => <AuthenticationGuard component={Inbox} eventId={eventId} replyStatus="assigned" />,

  "/events/:eventId/lists": ({ eventId }) => <AuthenticationGuard component={Lists} eventId={eventId} />,

  "/events/:eventId/reports/no_phone": ({ eventId }) => <AuthenticationGuard component={DeliveryIssuesReport} eventId={eventId} tabId="1" />,
  "/events/:eventId/reports/invalid": ({ eventId }) => <AuthenticationGuard component={DeliveryIssuesReport} eventId={eventId} tabId="2" />,
  "/events/:eventId/reports/landline": ({ eventId }) => <AuthenticationGuard component={DeliveryIssuesReport} eventId={eventId} tabId="3" />,
  "/events/:eventId/reports/not_yet_subscribed": ({ eventId }) => <AuthenticationGuard component={DeliveryIssuesReport} eventId={eventId} tabId="4" />,
  "/events/:eventId/reports/unsubscribed": ({ eventId }) => <AuthenticationGuard component={DeliveryIssuesReport} eventId={eventId} tabId="5" />,

  "/billing": () => <AuthenticationGuard component={Billing} />,

  "/settings": () => <AuthenticationGuard component={AccountSettings} />,
  "/settings/account": () => <AuthenticationGuard component={AccountSettings} />,
  "/settings/team": () => <AuthenticationGuard component={TeamSettings} />,
  "/settings/advanced": () => <AuthenticationGuard component={AdvancedSettings} />,

  "/events/:eventId/people/import": ({ eventId }) => <AuthenticationGuard component={PeopleImport} eventId={eventId} />,
  "/events/:eventId/people/flatfile-import": ({ eventId }) => <AuthenticationGuard component={PeopleImportViaFlatFile} eventId={eventId} />,

  "/events/:eventId/schedule": ({ eventId }) => <AuthenticationGuard component={Schedule} eventId={eventId} />,
  
  "*": () => <AuthenticationGuard component={Index} />,
};

const NotFoundPage = <div>Not found</div>;

const useRouter = () => {
  const routeResult = useRoutes(routes);
  const path = usePath();

  const getRoutes = useCallback(() => {
    return routeResult || <NotFoundPage />;
  }, [routeResult]);

  const navigateToLogin = useCallback((clean_redirect_url = false) => {
    if (path !== "/" && !clean_redirect_url) {
      store.set("REDIRECT_URI", path);
    }
    if (!["/login"].includes(path)) navigate("/login", true);
  }, [path]);

  return {
    getRoutes,
    navigate,
    path,
    navigateToLogin
  };
};

export default useRouter;
