import gql from 'graphql-tag'

export const SIGN_IN_MUTATION = gql`
  mutation SignInMutation($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      associateId
      authenticationToken
    }
  }
`

export const ADD_CUSTOMER_TO_LIST = params => gql`
mutation {
  addCustomersToList(${params})
}
`

export const REMOVE_CUSTOMER_FROM_LIST = params => gql`
mutation {
  removeCustomersFromList(${params})
}
`

export const EXPORT_CUSTOMERS = params => gql`
mutation {
  exportCustomers(
    ${params}
    customerAttributes: ["id", "org_customer_id", "firstname", "lastname", "email", "phone", "lifetime_spend", "associate_id"]
  ) {
    exportedCustomersCount
    fileUrl
  }
}
`

export const IMPORT_CUSTOMERS = (eventId, customers) => gql`
mutation {
 importCustomers(eventId: ${eventId}, customers: ${customers}) {
    message
 }
}
`

export const CREATE_CUSTOMER = gql`
mutation createCustomer($eventId: Int!, $firstName: String!, $lastName: String, $phone: String, $smsOptIn: Boolean) {
  createCustomer(eventId: $eventId, firstName: $firstName, lastName: $lastName, phone: $phone, smsOptIn: $smsOptIn) {
    id
  }
}
`

export const UPDATE_CUSTOMER = gql`
mutation UpdateCustomer($eventId: Int!, $id: Int!, $firstName: String!, $lastName: String, $phone: String, $smsOptIn: Boolean)  {
  updateCustomer(eventId: $eventId, id: $id, firstname: $firstName, lastname: $lastName, phone: $phone, smsOptIn: $smsOptIn) {
     id
  }
}
`

export const OPT_OUT_CUSTOMER = gql`
mutation CustomerOptOut($eventId: Int!, $id: Int!)  {
  customerOptOut(eventId: $eventId, id: $id) {
    id
    firstname
    lastname
    fullName
    formattedPhone
    phone
    email
    smsOptedIn
    smsOptInDate
    smsOptOutDate
  }
}
`

export const CREATE_LIST = (eventId, name) => gql`
mutation {
  createList(eventId: ${parseInt(eventId)}, name: "${name}") {
    id
  }
}
`
const escapeGraphQlString = (str) => str.replace(/"/g, '\\"');

export const UPDATE_LIST = (id, name) => gql`
mutation {
  updateList(id: ${parseInt(id)}, input: { name : "${escapeGraphQlString(name)}" }) {
    id
  }
}
`

export const ARCHIVE_LIST = (id) => gql`
mutation {
  updateList(id: ${parseInt(id)}, input: { active: false }) {
    id
  }
}
`

export const CREATE_SEGMENT = (eventId, name, description, searchConditions) => gql`
mutation {
  createSegment(
    eventId: ${parseInt(eventId)}
    name: "${name}"
    active: true
    description: "${description}"
    ${searchConditions}
  ) {
    id
  }
}
`

export const DISMISS_CUSTOMERS_FROM_LIST = params => gql`
mutation {
  dismissCustomersFromList(${params})
}
`

export const CREATE_BROADCAST = (eventId, templateId, name, sendAt) => gql`
mutation {
  createBroadcast(
    eventId: ${parseInt(eventId)}
    input: {
      templateId: ${templateId}
      channel: "email"
      name: "${name}"
      ${sendAt ? `sendAt: "${sendAt}"` : ''}
    }
  ) {
    id
  }
}
`

export const REASSIGN_CUSTOMERS = (params) => gql`
mutation {
  reassignCustomers(${params})
}
`

export const SEND_TEST_EMAIL = (eventId, broadcastId) => gql`
mutation {
  testBroadcast(eventId: ${parseInt(eventId)}, id: ${broadcastId}) {
    broadcast {
      id
    }
    message
  }
}
`

export const UPDATE_BROADCAST = ({ eventId, broadcastId, name, templateId, listId, sendAt }) => gql`
mutation {
  updateBroadcast(
  eventId: ${parseInt(eventId)},
  id: ${broadcastId}
  input:
    {
      name: "${name}"
      templateId: ${templateId}
      channel: "email"
      listId: ${listId}
      ${sendAt ? `sendAt: "${sendAt}"` : ''}
    }
  ) {
    id
  }
}
`

export const QUEUE_BROADCAST = (eventId, broadcastId) => gql`
mutation {
  queueBroadcast(eventId: ${eventId}, id: ${broadcastId}) {
    id
  }
}
`

export const CREATE_SMS_BROADCAST = gql`
mutation createSmsBroadcast($eventId: Int!, $name: String!, $uniqueLink: Boolean, $shortenedLinks: JSON, $message: String, $sendAt: String, $isImmediate: Boolean!, $status: String!, $listId: Int, $files: Upload)  {
  createSmsBroadcast(eventId: $eventId, name: $name, uniqueLink: $uniqueLink, shortenedLinks: $shortenedLinks, message: $message, sendAt: $sendAt, isImmediate: $isImmediate, status: $status, listId: $listId, files: $files) {
     id
  }
}
`

export const UPDATE_SMS_BROADCAST = gql`
mutation updateSmsBroadcast($broadcastId: Int!, $name: String!, $uniqueLink: Boolean, $shortenedLinks: JSON, $message: String, $sendAt: String, $isImmediate: Boolean!, $status: String!, $listId: Int, $files: Upload)  {
  updateSmsBroadcast(broadcastId: $broadcastId, name: $name, uniqueLink: $uniqueLink, shortenedLinks: $shortenedLinks, message: $message, sendAt: $sendAt, isImmediate: $isImmediate, status: $status, listId: $listId, files: $files) {
     id
  }
}
`

export const UPDATE_BROADCAST_STATUS = gql`
mutation updateSmsBroadcast($broadcastId: Int!, $status: String!, $listId: Int, $uniqueLink: Boolean)  {
  updateSmsBroadcast(broadcastId: $broadcastId, status: $status, listId: $listId, uniqueLink: $uniqueLink) {
    id
  }
}
`

export const DELETE_SMS_BROADCAST = gql`
mutation deleteBroadcast($eventId: Int!, $broadcastId: Int!) {
  deleteBroadcast(eventId: $eventId, id: $broadcastId) {
     id
  }
}
`

export const TEST_SMS_MESSAGE = gql`
mutation testSmsMessage($eventId: Int!, $message: String!, $broadcastId: Int, $files: Upload)  {
  testSmsMessage(eventId: $eventId, message: $message, broadcastId: $broadcastId, files: $files) {
     body
  }
}
`

export const DELETE_BROADCAST = (eventId, id) => gql`
mutation {
  deleteBroadcast(eventId: ${parseInt(eventId)}, id: ${id}) {
    id
  }
}
`

export const CREATE_TEMPLATE = (eventId, title, subject, htmlDesign, body, privateTemplate) => gql`
mutation {
  createTemplate(
    eventId: ${parseInt(eventId)}
    input: {
      subject: "${subject}"
      channel: "email"
      body: "${body}"
      htmlDesign: "${htmlDesign}"
      title: "${title}"
      private: ${privateTemplate}
    }
  ) {
    id
    subject
    body
    htmlDesign
    title
    createdAt
  }
}
`

export const UPDATE_TEMPLATE = (id, title, subject, htmlDesign, body, privateTemplate) => gql`
mutation {
  updateTemplate(
    id: ${id}
    input: {
      subject: "${subject}"
      channel: "email"
      body: "${body}"
      htmlDesign: "${htmlDesign}"
      title: "${title}"
      private: ${privateTemplate}
    }
  ) {
    id
    subject
    body
    htmlDesign
    title
    createdAt
  }
}
`

export const RESET_PASSWORD = email => gql`
mutation {
  requestNewPassword(email: "${email}") {
    message
  }
}
`

export const UPDATE_ASSOCIATE = gql`
mutation UpdateAssociate($firstName: String, $lastName: String, $mobilephone: String, $timeZone: String, $inboundMessageOptIn: Boolean)  {
  updateAssociate(firstname: $firstName, lastname: $lastName, mobilephone: $mobilephone, timeZone: $timeZone, inboundMessageOptIn: $inboundMessageOptIn) {
     id
  }
}
`

export const UPDATE_ASSOCIATE_PASSWORD = (token, password, confirm) => gql`
mutation {
  updateAssociatePassword(
      resetPasswordToken: "${token}"
      newPassword: "${password}"
      newPasswordConfirmation: "${confirm}"
    ) {
      authenticationToken
    }
}
`

export const UPDATE_LOGGED_IN_ASSOCIATE_PASSWORD = gql`
mutation UpdateLoggedInAssociatePassword($currentPassword: String!, $password: String!, $passwordConfirmation: String!) {
  updateLoggedInAssociatePassword(currentPassword: $currentPassword, password: $password, passwordConfirmation: $passwordConfirmation) {
    id
  }
}
`

export const UPLOAD_TEMPLATE_FILES = templateId => gql`
mutation($files: [Upload!]!) {
  addAttachments(parentId: ${templateId}, parentType: Template, files: $files) {
    id
  }
}
`

export const REMOVE_ATTACHMENT = id => gql`
mutation {
  removeAttachment(id: ${id}) {
    id
  }
}
`
export const UPDATE_CUSTOMER_EMAIL = gql`
mutation UpdateCustomerEmail($eventId: Int!, $id: Int!, $email: String!)  {
  updateCustomer(eventId: $eventId, id: $id, email: $email) {
     id
     email
  }
}
`

export const UPDATE_CUSTOMER_PHONE = gql`
mutation UpdateCustomerPhone($eventId: Int!, $id: Int!, $phone: String!) {
  updateCustomer(eventId: $eventId, id: $id, phone: $phone)
  {
     id
     phone
     formattedPhone
  }
}
`

export const ADD_ORG_PAYMENT_METHOD = gql`
  mutation AddOrgPaymentMethod($card: String!) {
    addOrgPaymentMethod(card: $card) {
        id
    }
  }
`

export const DELETE_ORG_PAYMENT_METHOD = gql`
  mutation DeleteOrgPaymentMethod {
   deleteOrgPaymentMethod {
        id
    }
  }
`

export const SEND_MESSAGE = gql`
mutation sendMessage($eventId: Int!, $customerId: ID!, $subject: String, $body: String!, $channel: String!, $file: Upload)  {
  sendMessage(eventId: $eventId, customerId: $customerId, subject: $subject, body: $body, channel: $channel, file: $file) {
     id
  }
}
`

export const ARCHIVE_MESSAGE = gql`
mutation archiveMessage($eventId: Int!, $messageId: Int!)  {
  archiveMessage(eventId: $eventId, messageId: $messageId) {
     id
  }
}
`

export const IMPORT_CUSTOMERS_FROM_CSV = gql`
mutation importCustomersFromCsv($eventId: Int!, $file: Upload!)  {
  importCustomersFromCsv(eventId: $eventId, file: $file) {
    id
  }
}
`

export const CREATE_REMINDER = gql`
mutation createReminder($eventId: Int!, $customerId: Int!, $note: String, $dueAt: ISO8601DateTime!) {
  createReminder(eventId: $eventId, customerId: $customerId, note: $note, dueAt: $dueAt) {
    id
    dueAt
    note
    completed
  }
}
`

export const DELETE_REMINDER = gql`
mutation deleteReminder($id: Int!) {
  deleteReminder(id: $id) {
    id
  }
}
`

export const CREATE_EVENT = gql`
mutation createEvent($name: String!) {
  createEvent(name: $name) {
    id
    name
  }
}
`

export const ARCHIVE_CUSTOMER = gql`
mutation ArchiveCustomer($eventId: Int!, $id: Int!) {
  archiveCustomer(eventId: $eventId, id: $id) {
    message
    customer {
      id
      active
      firstname
      lastname
    }
  }
}
`

export const IMPORT_CUSTOMER_RECORDS = gql`
mutation ImportCustomerRecords($eventId: Int!, $spaceId: String!, $sheetId: String!, $workbookId: String!, $records: [CustomerRecordInput!]!) {
  importCustomerRecords(eventId: $eventId, spaceId: $spaceId, sheetId: $sheetId, workbookId: $workbookId, records: $records) {
    message
  }
}
`

export const CREATE_SMS_BROADCAST_FOR_CUSTOMER = gql`
mutation CreateSmsBroadcastForCustomer($eventId: Int!, $customerIds: [Int!]!, $sendDatetimeUtc: String!, $messageBody: String!, $files: Upload) {
  createSmsBroadcastForCustomer(eventId: $eventId, customerIds: $customerIds, sendDatetimeUtc: $sendDatetimeUtc, messageBody: $messageBody, files: $files) {
    id
  }
}
`

export const ASSIGN_MESSAGE_TO_ASSOCIATE = gql`
  mutation AssignMessageToAssociate($eventId: Int!, $messageId: Int!, $assignedToAssociateId: Int!) {
    assignMessageToAssociate(eventId: $eventId, messageId: $messageId, assignedToAssociateId: $assignedToAssociateId) {
      message
    }
  }
`;

export const UNASSIGN_MESSAGE_FROM_ASSOCIATE = gql`
  mutation UnassignMessageFromAssociate($eventId: Int!, $messageId: Int!) {
    unassignMessageFromAssociate(eventId: $eventId, messageId: $messageId) {
      message
    }
  }
`;

export const SWITCH_ASSOCIATE_ACCOUNT = gql`
  mutation SwitchAssociateAccount($accountId: Int!) {
    switchAssociateAccount(accountId: $accountId) {
      message
    }
  }
`;

export const CREATE_EVENT_SESSION = gql`
  mutation CreateSession($eventId: Int!, $name: String!, $description: String!, $locationName: String!, $locationUrl: String!, $startDatetimeUtc: String, $endDatetimeUtc: String, $filterBy: String, $listId: Int, $customerId: Int) {
    createSession(eventId: $eventId, name: $name, startDatetimeUtc: $startDatetimeUtc, endDatetimeUtc: $endDatetimeUtc, description: $description, locationName: $locationName, locationUrl: $locationUrl, filterBy: $filterBy, listId: $listId, customerId: $customerId) {
      name
      description
      startDatetimeUtc
      endDatetimeUtc
      locationName
      locationUrl
      listId
      customerId
    }
  }
`;

export const UPDATE_EVENT_SESSION = gql`
  mutation UpdateSession($id: Int!, $listId: Int, $customerId: Int, $name: String, $description: String, $locationName: String, $locationUrl: String, $startDatetimeUtc: String, $endDatetimeUtc: String, $filterBy: String) {
    updateSession(id: $id, listId: $listId, customerId: $customerId, name: $name, description: $description, locationName: $locationName, locationUrl: $locationUrl, startDatetimeUtc: $startDatetimeUtc, endDatetimeUtc: $endDatetimeUtc, filterBy: $filterBy) {
      id
      listId
      customerId
      name
      description
      locationName
      locationUrl
      startDatetimeUtc
      endDatetimeUtc
    }
  }
`;

export const DELETE_EVENT_SESSION = gql`
  mutation DeleteSession($id: Int!) {
    deleteSession(id: $id) {
      message
    }
  }
`

// export const UPDATE_EVENT = gql`
//   mutation UpdateEvent($id: ID!, $name: String, $phoneNumber: String, $locationName: String, $locationUrl: String, $startDatetimeUtc: ISO8601DateTime, $endDatetimeUtc: ISO8601DateTime, $timezone: String) {
//     updateEvent(id: $id, name: $name, phoneNumber: $phoneNumber, locationName: $locationName, locationUrl: $locationUrl, startDatetimeUtc: $startDatetimeUtc, endDatetimeUtc: $endDatetimeUtc, timezone: $timezone) {
//       id
//       name
//       phoneNumber
//       locationName
//       locationUrl
//       startDatetimeUtc
//       endDatetimeUtc
//       timezone
//       qrCodeUrl
//       contactCardUrl
//     }
//   }
// `;

export const UPDATE_EVENT = gql`
mutation UpdateEvent(
    $id: ID!,
    $name: String,
    $phoneNumber: String,
    $locationName: String,
    $locationUrl: String,
    $startDatetimeUtc: ISO8601DateTime,
    $endDatetimeUtc: ISO8601DateTime,
    $timezone: String
) {
    updateEvent(
        id: $id,
        name: $name,
        phoneNumber: $phoneNumber,
        locationName: $locationName,
        locationUrl: $locationUrl,
        startDatetimeUtc: $startDatetimeUtc,
        endDatetimeUtc: $endDatetimeUtc,
        timezone: $timezone
    ) {
        id
        name
        phoneNumber
        locationName
        locationUrl
        startDatetimeUtc
        endDatetimeUtc
        timezone
        qrCodeUrl
        contactCardUrl
    }
}
`;